import logo from './logo.png';
import './App.css';
import Banner from './Banner';

function App() {
  return (
    <div className="App font-inter bg-gray-900 flex flex-col h-full">
      <div>
        <Banner/>
      </div>
      
      <div className="App-header flex flex-1 flex-col items-center justify-center tracking-widest text-white">
        {/* <div className='w-2 sm:w-3/4 '> */}
          <img src={logo} className="App-logo drop-shadow-sm  w-80" alt="logo" />
        {/* </div> */}
        
        <p className='text-7xl mb-5 drop-shadow-md shadow-white font-bold'>
          COMING <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-400">SOON</span>
        </p>
        <p className='text-2xl font-bold drop-shadow-md shadow-white'>
          PARIS
        </p>
      </div>
    </div>
  );
}

export default App;
