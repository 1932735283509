/* This example requires Tailwind CSS v2.0+ */
import { XIcon } from '@heroicons/react/outline'

export default function Banner() {
  return (
    <div className="relative bg-gradient-to-r from-purple-500 to-pink-400">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="sm:text-center sm:px-16">
          <p className="font-medium text-white">
            <span className="md:hidden">Something big is coming!</span>
            <span className="hidden md:inline">Big news! Fragment Hub is coming.</span>
            <span className="block sm:ml-2 sm:inline-block">
              <a href="https://www.linkedin.com/company/byfragment" className="text-white font-bold underline">
                {' '}
                Follow Us <span aria-hidden="true">&rarr;</span>
              </a>
            </span>
          </p>
        </div>
        
      </div>
    </div>
  )
}